<template>
    <HeroPage v-if="pageData" :data="pageData"></HeroPage>

    <FaqCollection :filterFaqBy="[
        'Pricing and payment',
        'General',
        'For clubs',
        'TBD 1',
        'TBD 2',
        'TBD 3'
        // 'General',
        // 'For clubs',
        // 'Pricing and payment',
        // 'TBD 1',
        // 'TBD 2',
        // 'TBD 3'

    ]">
        <!-- <h2 class="section-title">Frequently asked questions</h2>
        <span class="section-subtitle">Before you contact us, maybe you can find your answer here?</span> -->
    </FaqCollection>
</template>

<script>
import { fetchPageBySlug } from "@streampac.io/chef_sp_1";

// @import component
import HeroPage from "@/components/HeroPage";
import FaqCollection from "@/components/FaqCollection";

export default {
    name: "FaqPage",
    inject: ["isAuthenticated"],
    components: {
        HeroPage,
        FaqCollection,
    },
    data() {
        return {
            pageData: null,
            showFaq: false,
        };
    },
    created() {
        this.fetchPage();
        // setTimeout(() => {
        // 	this.showFaq = true;
        // }, 1000);
    },
    methods: {
        fetchPage() {
            fetchPageBySlug(this.isAuthenticated, "faq").then((response) => {
                //console.log(response);
                this.pageData = response;
            });
        },
        // loadFaq() {
        // 	this.showFaq = true;
        // 	console.log("wtf");
        // 	// console.log(val);
        // 	// console.log("faq should be loaded");
        // },
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>
